import React, { useState } from 'react'
import {
  EnhancedDialog,
  EnhancedDialogProps,
  EnhancedDialogTitle,
} from './EnhancedDialog'
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material'
import TenantSelector from './TenantSelector'
import { useLocalStorage } from 'react-use'
import { LocalStorageKey } from 'hooks/useLogout'
import { useAuth } from '@frontegg/react'
import useAuthTenants from 'hooks/useAuthTenants'
import { useRouter } from 'next/router'
import {
  CARE_STUDIO_DEFAULT_URL,
  CARE_STUDIO_URL,
  PORTAL_DEFAULT_URL,
} from './MainNavBar'

export type SelectAccountDialogProps = EnhancedDialogProps & {
  variant?: 'select' | 'switch'
  onClose?: () => void
}

export const SelectAccountDialog = ({
  variant = 'select',
  onClose,
  ...dialogProps
}: SelectAccountDialogProps) => {
  const router = useRouter()
  const { user: fronteggUser, isAuthenticated } = useAuth()
  const { switchTenant } = useAuthTenants()
  const [, setDisplayAccountSelectionDialogOnLogin] = useLocalStorage(
    LocalStorageKey.DISPLAY_ACCOUNT_SELECTION_DIALOG_ON_LOGIN,
    true,
  )
  const [currentTenantId, setCurrentTenantId] = useState(
    fronteggUser?.tenantId,
  )
  const isCareStudioUrl = !!router.pathname.match(CARE_STUDIO_URL)

  return (
    <EnhancedDialog
      {...dialogProps}
      disableCloseOnBackdropClick
      maxWidth="xs"
      fullWidth
    >
      <EnhancedDialogTitle
        onClose={
          variant === 'switch'
            ? () => {
                onClose?.()
              }
            : null
        }
      >
        {variant === 'select' ? 'Select' : 'Switch'} Account
      </EnhancedDialogTitle>
      <DialogContent>
        {variant === 'select' ? (
          <>
            <Typography variant="body2">
              Choose the account that you would like to log into
            </Typography>
            <TenantSelector
              selectedTenantId={currentTenantId}
              onChange={(tenantId) => {
                setCurrentTenantId(tenantId)
              }}
            />
          </>
        ) : (
          <TenantSelector
            selectedTenantId={fronteggUser?.tenantId}
            onChange={(tenantId) => {
              router.push(
                isCareStudioUrl
                  ? CARE_STUDIO_DEFAULT_URL
                  : PORTAL_DEFAULT_URL,
              )
              if (isAuthenticated) {
                switchTenant({ tenantId })
              }
            }}
          />
        )}
      </DialogContent>
      {variant === 'select' && (
        <>
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              onClick={async () => {
                // To make sure `setDisplayAccountSelectionDialogOnLogin` is executed before `switchTenant`
                await new Promise<void>((resolve) => {
                  setDisplayAccountSelectionDialogOnLogin(false)
                  resolve()
                })

                if (isAuthenticated) {
                  console.log('switching...')
                  switchTenant({ tenantId: currentTenantId })
                }
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </>
      )}
    </EnhancedDialog>
  )
}
